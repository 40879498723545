import React, { useEffect, useState } from "react";
import { getUserProfile, generatePrompt } from "../services/api";
import Navbar from "./Navbar";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [tone, setTone] = useState("");
  const [emailExamples, setEmailExamples] = useState("");
  const [suggestions, setSuggestions] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();
      const data = response.data;
      setUser(data);
      setName(data.name || "");
      setCompany(data.company || "");
      setRole(data.role || "");
      setTone(data.tone || "");
      setEmailExamples(data.emailExamples || "");
      setSuggestions(data.suggestions || "");
    } catch (err) {
      setError("Failed to fetch user profile");
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    const promptData = { name, company, role, tone, emailExamples, suggestions };

    try {
      await generatePrompt(promptData);
      setSuccess("Perfil actualizado exitosamente y prompt regenerado");
      setError("");
      // Refrescar los datos del usuario
      fetchUserProfile();
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update profile");
      setSuccess("");
    }
  };

  if (!user) return <div className="flex justify-center items-center h-screen">Loading...</div>;

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      <div className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">Perfil de Usuario</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {success && <p className="text-green-500 mb-4">{success}</p>}
            <div className="mb-6">
              <p className="text-lg"><strong>Email:</strong> {user.email}</p>
            </div>
            <form onSubmit={handleUpdateProfile}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-lg font-semibold mb-2"
                    htmlFor="name"
                  >
                    Nombre
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Ingresa tu nombre..."
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-lg font-semibold mb-2"
                    htmlFor="company"
                  >
                    Nombre de la Empresa
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="company"
                    type="text"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="Ingresa el nombre de tu empresa..."
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-lg font-semibold mb-2"
                    htmlFor="role"
                  >
                    Tu Cargo
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="role"
                    type="text"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    placeholder="Ingresa tu cargo..."
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-lg font-semibold mb-2"
                    htmlFor="tone"
                  >
                    Tono de Comunicación Preferido
                  </label>
                  <select
                    className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="tone"
                    value={tone}
                    onChange={(e) => setTone(e.target.value)}
                    required
                  >
                    <option value="">Selecciona un tono</option>
                    <option value="formal">Formal</option>
                    <option value="amistoso">Amistoso</option>
                    <option value="casual">Casual</option>
                    <option value="profesional">Profesional</option>
                  </select>
                </div>
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-lg font-semibold mb-2"
                  htmlFor="emailExamples"
                >
                  Ejemplos de Emails que Sueles Enviar
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="emailExamples"
                  rows="5"
                  value={emailExamples}
                  onChange={(e) => setEmailExamples(e.target.value)}
                  placeholder="Ingresa algunos ejemplos de emails que sueles enviar..."
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-lg font-semibold mb-2"
                  htmlFor="suggestions"
                >
                  Sugerencias Adicionales
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="suggestions"
                  rows="3"
                  value={suggestions}
                  onChange={(e) => setSuggestions(e.target.value)}
                  placeholder="Ingresa cualquier sugerencia adicional que tengas para el asistente..."
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  type="submit"
                >
                  Actualizar Perfil
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
