import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // If we receive a 401 response, log out the user
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export const register = (email, password) => api.post('/auth/register', { email, password });
export const login = (email, password) => api.post('/auth/login', { email, password });
export const getUserProfile = () => api.get('/auth/user');
export const updatePrompt = (prompt) => api.post('/auth/update_prompt', { prompt });
export const configureEmailProvider = (provider, config) => api.post('/email-integration/integrate', { provider, ...config });
export const fetchEmails = (lastfetchtime) => api.get('/emails/get?last_fetch_time=' + lastfetchtime);
export const generateResponse = (email, suggestion) => api.post('/emails/generate-response', { email, suggestion });
export const sendResponse = (emailId, response, to, cc, bcc, original_message_id, references) => 
  api.post('/emails/send-response', { 
    emailId, 
    response, 
    to,
    cc, 
    bcc, 
    original_message_id, 
    references 
  });
export const getUserEmailConfig = () => api.get('/email-integration/config');
export const generatePrompt = (promptData) => api.post('/auth/generate_prompt', promptData);

export const transcribeAudio = (formData) => api.post('/emails/transcribe-audio', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default api;