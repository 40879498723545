import {
  ArrowLeft,
  ArrowRight,
  Inbox,
  Mail,
  Mic,
  RefreshCw,
  Send,
  StopCircle,
  ThumbsUp,
  User,
  X,
  Reply,
  ReplyAll,
  Forward,
  PenTool
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { fetchEmails, generateResponse, sendResponse, transcribeAudio } from "../services/api";
import Navbar from "./Navbar";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { useInterval } from '../hooks/useInterval'; // Necesitarás crear este hook personalizado

const EmaliaSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="w-8 h-8"
  >
    <rect width="24" height="24" rx="4" fill="#FF4B91" />
    <path d="M4 8l8 5 8-5v8H4V8z" fill="white" />
    <path d="M4 8l8 5 8-5" fill="none" stroke="white" strokeWidth="1.5" />
  </svg>
);

const EmailResponseApp = () => {
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [suggestion, setSuggestion] = useState("");
  const [generatedResponse, setGeneratedResponse] = useState("");
  const [editableResponse, setEditableResponse] = useState("");
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [notification, setNotification] = useState(null);
  const [notificationTimeout, setNotificationTimeout] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [replyType, setReplyType] = useState(null);
  const [replyRecipients, setReplyRecipients] = useState(null);
  const [isComposingNew, setIsComposingNew] = useState(false);
  const [subject, setSubject] = useState("");
  const [isBackgroundLoading, setIsBackgroundLoading] = useState(false);
  const [hasNewEmails, setHasNewEmails] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    fetchEmailsData(true);
  }, []);

  useInterval(() => {
    fetchEmailsData(false);
  }, 60000);

  const fetchEmailsData = async (isInitialFetch) => {
    if (isInitialFetch) {
      setIsLoading(true);
    } else {
      setIsBackgroundLoading(true);
    }

    try {
      const storedThreads = JSON.parse(
        localStorage.getItem("emailThreads") || "[]"
      );
      const storedLastFetchTime = localStorage.getItem("lastFetchTime");

      if (isInitialFetch) {
        setLastFetchTime(storedLastFetchTime);
        setThreads(storedThreads);
      }

      const response = await fetchEmails(storedLastFetchTime);
      const newEmails = response.data.emails;
      const newLastFetchTime = response.data.last_fetch_time;

      if (newEmails.length > 0 || storedThreads.length > 0) {
        const allEmails = [...storedThreads, ...newEmails];
        const uniqueEmails = allEmails.flat().filter((email, index, self) =>
          index === self.findIndex((t) => t.message_id === email.message_id)
        );

        const updatedThreads = groupEmailsIntoThreads(uniqueEmails);
        setThreads(updatedThreads);
        localStorage.setItem("emailThreads", JSON.stringify(updatedThreads));

        if (!isInitialFetch) {
          setHasNewEmails(true);
        }
      }

      setLastFetchTime(newLastFetchTime);
      localStorage.setItem("lastFetchTime", newLastFetchTime);
    } catch (error) {
      console.error("Error fetching emails:", error);
      showNotification("error",
        "Error al obtener los correos electrónicos. Por favor, inténtalo de nuevo."
      );
    } finally {
      setIsLoading(false);
      setIsBackgroundLoading(false);
      setIsInitialLoad(false);
    }
  };

  const handleSelectThread = (thread) => {
    setSelectedThread(thread);
    setSuggestion("");
    setGeneratedResponse("");
    setEditableResponse("");
    setIsWaitingForApproval(false);
    setIsSidebarOpen(false);
    setReplyRecipients(null);
    setIsComposingNew(false);
    setSubject("");
    setHasNewEmails(false);
  };

  // Función de notificación del navegador comentada
  /*
  const showBrowserNotification = (title, body) => {
    if ("Notification" in window) {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          new Notification(title, { body });
        }
      });
    }
  };
  */

  const LoadingSpinner = () => (
    <div className="flex flex-col justify-center items-center h-full">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 mb-4"></div>
      <p className="text-lg text-gray-600 font-semibold">
        Cargando tus correos electrónicos...
      </p>
    </div>
  );

  const groupEmailsIntoThreads = (emails) => {
    const threadMap = new Map();

    // Flatten the array of emails if necessary
    const flatEmails = emails.flat();

    // First pass: create threads
    flatEmails.forEach((email) => {
      const threadId = email.message_id;
      if (!threadMap.has(threadId)) {
        threadMap.set(threadId, [email]);
      } else {
        const existingEmails = threadMap.get(threadId);
        if (!existingEmails.some(e => e.message_id === email.message_id)) {
          existingEmails.push(email);
        }
      }
    });

    // Second pass: link replies to their parent threads
    threadMap.forEach((thread, threadId) => {
      thread.forEach((email) => {
        if (email.in_reply_to) {
          const parentId = email.in_reply_to.trim();
          if (threadMap.has(parentId)) {
            // Move this email to the parent thread
            const parentThread = threadMap.get(parentId);
            if (!parentThread.some(e => e.message_id === email.message_id)) {
              parentThread.push(email);
            }
            // Remove it from the current thread if it's not the original thread
            if (parentId !== threadId) {
              const index = thread.findIndex(e => e.message_id === email.message_id);
              if (index > -1) {
                thread.splice(index, 1);
              }
            }
          }
        }
      });
    });

    // Remove empty threads
    for (let [threadId, thread] of threadMap.entries()) {
      if (thread.length === 0) {
        threadMap.delete(threadId);
      }
    }

    // Sort emails within each thread by date
    threadMap.forEach((thread) => {
      thread.sort((a, b) => new Date(a.date) - new Date(b.date));
    });

    // Convert map to array and sort threads by the date of the most recent email
    return Array.from(threadMap.values())
      .filter((thread) => thread.length > 0)
      .sort(
        (a, b) =>
          new Date(b[b.length - 1].date) - new Date(a[a.length - 1].date)
      );
  };

  const handleSuggestionChange = (e) => {
    setSuggestion(e.target.value);
  };

  const handleGenerateResponse = async () => {
    setIsGenerating(true);
    try {
      let emailBody = '';
      let emailSubject = '';

      if (isComposingNew) {
        // Si estamos componiendo un nuevo correo, usamos solo la sugerencia
        emailBody = suggestion;
        emailSubject = 'Nuevo correo';
      } else if (selectedThread && selectedThread.length > 0) {
        // Si es una respuesta, usamos el último mensaje del hilo
        const lastMessage = selectedThread[selectedThread.length - 1];
        // Remove images from the HTML body before sending it for response generation
        emailBody = lastMessage.body.replace(/<img[^>]*>/g, "");
        emailSubject = lastMessage.subject;
      } else {
        throw new Error('No hay contenido para generar una respuesta');
      }

      const response = await generateResponse(emailBody, suggestion, emailSubject);
      setGeneratedResponse(response.data.response);
      setEditableResponse(response.data.response);
      setIsWaitingForApproval(true);
    } catch (error) {
      console.error("Error generating response:", error);
      showNotification("error",
        "Error al generar la respuesta. Por favor, inténtalo de nuevo."
      );
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRegenerateResponse = async () => {
    setIsGenerating(true);
    setIsWaitingForApproval(false);
    setGeneratedResponse("");
    setEditableResponse("");
    await handleGenerateResponse();
  };

  const handleEditResponse = (e) => {
    setEditableResponse(e.target.value);
  };

  const handleApproveResponse = async () => {
    setIsSending(true);
    try {
      const lastMessage = selectedThread ? selectedThread[selectedThread.length - 1] : null;
      const response = await sendResponse(
        lastMessage ? lastMessage.id : null,
        editableResponse,
        replyRecipients.to,
        replyRecipients.cc,
        replyRecipients.bcc,
        lastMessage ? lastMessage.message_id : null,
        lastMessage ? lastMessage.references : null,
        isComposingNew ? subject : `Re: ${selectedThread ? selectedThread[0].subject : ''}`
      );

      if (response.status === 200) {
        const newMessageId = response.data.message_id;
        showNotification("success", "¡Respuesta enviada exitosamente!");

        const newEmail = {
          id: newMessageId,
          body: editableResponse,
          sender: "You",
          recipient: replyRecipients.to.join(", "),
          cc: replyRecipients.cc,
          bcc: replyRecipients.bcc,
          date: new Date().toISOString(),
          is_sent: true,
          message_id: newMessageId,
          in_reply_to: lastMessage ? lastMessage.message_id : null,
          references: lastMessage
            ? lastMessage.references
              ? `${lastMessage.references} ${lastMessage.message_id}`
              : lastMessage.message_id
            : null,
          subject: isComposingNew ? subject : `Re: ${selectedThread ? selectedThread[0].subject : ''}`,
        };

        if (isComposingNew) {
          // Si es un nuevo correo, crear un nuevo hilo
          const newThread = [newEmail];
          setThreads(prevThreads => [newThread, ...prevThreads]);
          setSelectedThread(newThread);
        } else if (selectedThread) {
          // Si es una respuesta, actualizar el hilo existente
          const updatedThread = [...selectedThread, newEmail];
          setSelectedThread(updatedThread);
          setThreads(prevThreads => {
            const updatedThreads = prevThreads.map(thread =>
              thread[0].message_id === updatedThread[0].message_id ? updatedThread : thread
            );
            return groupEmailsIntoThreads(updatedThreads);
          });
        }

        setSuggestion("");
        setGeneratedResponse("");
        setEditableResponse("");
        setIsWaitingForApproval(false);
        setReplyRecipients(null);
        setIsComposingNew(false);
        setSubject("");
      } else {
        throw new Error("La respuesta del servidor no fue exitosa");
      }
    } catch (error) {
      console.error("Error sending response:", error);
      showNotification("error",
        "Error al enviar la respuesta. Por favor, inténtalo de nuevo."
      );
    } finally {
      setIsSending(false);
    }
  };

  const shortenLink = (url) => {
    try {
      const urlObject = new URL(url);
      const domain = urlObject.hostname;
      const path = urlObject.pathname;
      if (path.length > 15) {
        return `${domain}/...${path.slice(-10)}`;
      }
      return `${domain}${path}`;
    } catch (e) {
      return url;
    }
  };

  const renderEmailBody = (body) => {
    // Verificar si el contenido es HTML basado en elementos específicos como DOCTYPE
    const isHtml =
      body.includes('<!DOCTYPE html>') ||
      /<html|<head|<body|<div|<span|<a|<p|<br/i.test(body);

    if (isHtml) {
      // Si es HTML, retornarlo tal cual
      return {
        __html: body,
        isHtml: true,
      };
    } else {
      // Si es texto plano, eliminar caracteres de escape y decodificar caracteres especiales
      const cleanedBody = body
        .replace(/\\r\\n/g, '\n')
        .replace(/\\n/g, '\n')
        .replace(/\\"/g, '"')
        .replace(/\\/g, '')
        .replace(/\u00f3/g, 'ó')
        .replace(/\u00e1/g, 'á')
        .replace(/\u00e9/g, 'é')
        .replace(/\u00ed/g, 'í')
        .replace(/\u00fa/g, 'ú');

      // Convertir URLs en enlaces
      const processedPlainText = cleanedBody
        .split('\n')
        .map((line) => {
          const lineWithLinks = line.replace(/(https?:\/\/[^\s]+)/g, (url) => {
            const shortUrl = shortenLink(url);
            return `<a href="${url}" class="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">${shortUrl}</a>`;
          });
          return `<p>${lineWithLinks}</p>`;
        })
        .join('');

      return {
        __html: processedPlainText,
        isHtml: false,
      };
    }
  };

  const EmailBody = ({ body }) => {
    const { __html, isHtml } = renderEmailBody(body);

    return (
      <div
        dangerouslySetInnerHTML={{ __html }}
        className={`email-body ${isHtml ? 'html-content' : 'plain-text'}`}
      />
    );
  };

  const emailStyles = `
    .email-body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
    }
    .email-body.plain-text p {
      margin: 0;
      padding: 0;
      min-height: 1em;
      white-space: pre-wrap; /* Preserva los saltos de línea y espacios */
    }
    .email-body a {
      color: #3b82f6;
      text-decoration: none;
    }
    .email-body a:hover {
      text-decoration: underline;
    }
  `;

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
      const recorder = new MediaRecorder(stream);
      setAudioRecorder(recorder);
      
      const chunks = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/wav' });
        await handleAudioTranscription(audioBlob);
      };

      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
      showNotification("error",
        "Error al iniciar la grabación. Por favor, inténtalo de nuevo."
      );
    }
  };

  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
    }
    if (audioStream) {
      audioStream.getTracks().forEach(track => track.stop());
    }
  };

  const handleAudioTranscription = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');
      const response = await transcribeAudio(formData);
      setSuggestion(prevSuggestion => prevSuggestion + ' ' + response.data.transcription);
    } catch (error) {
      console.error("Error transcribing audio:", error);
      showNotification("error",
        "Error al transcribir el audio. Por favor, inténtalo de nuevo."
      );
    }
  };

  const handleReply = (type) => {
    const lastEmail = selectedThread ? selectedThread[selectedThread.length - 1] : null;
    let recipients = {
      to: [],
      cc: [],
      bcc: []
    };

    switch (type) {
      case "reply":
        recipients.to = lastEmail ? [lastEmail.sender] : [];
        break;
      case "replyAll":
        if (lastEmail) {
          recipients.to = [lastEmail.sender];
          if (lastEmail.recipient && lastEmail.recipient !== "You") {
            recipients.to.push(lastEmail.recipient);
          }
          recipients.cc = lastEmail.cc || [];
          // Eliminar tu propia dirección de correo de los destinatarios
          recipients.to = recipients.to.filter(email => email !== "soporte@emalia.io" && email !== "You");
          recipients.cc = recipients.cc.filter(email => email !== "soporte@emalia.io" && email !== "You");
        }
        break;
      case "forward":
        // Para reenvío, dejamos los campos de destinatarios vacíos
        // pero podríamos añadir el contenido del email original al cuerpo del mensaje
        if (lastEmail) {
          setSuggestion(`---------- Forwarded message ---------\nFrom: ${lastEmail.sender}\nDate: ${new Date(lastEmail.date).toLocaleString()}\nSubject: ${lastEmail.subject}\nTo: ${lastEmail.recipient}\n\n${lastEmail.body}`);
        }
        break;
      case "new":
        // Para un nuevo correo, simplemente dejamos todos los campos vacíos
        setSelectedThread(null);
        setSubject("");
        recipients = { to: [], cc: [], bcc: [] };
        break;
    }

    setReplyRecipients(recipients);
    setIsWaitingForApproval(false);
    setIsComposingNew(type === "new");
    setSuggestion("");
    setGeneratedResponse("");
    setEditableResponse("");
  };

  const handleRecipientChange = (field, value) => {
    setReplyRecipients(prev => ({
      ...prev,
      [field]: value.split(',').map(email => email.trim())
    }));
  };

  const showNotification = (type, message, duration = 5000) => {
    // Limpiar cualquier timeout existente
    if (notificationTimeout) {
      clearTimeout(notificationTimeout);
    }

    setNotification({ type, message });

    // Establecer un nuevo timeout para limpiar la notificación
    const timeout = setTimeout(() => {
      setNotification(null);
    }, duration);

    setNotificationTimeout(timeout);
  };

  useEffect(() => {
    return () => {
      if (notificationTimeout) {
        clearTimeout(notificationTimeout);
      }
    };
  }, [notificationTimeout]);

  return (
    <div className="flex flex-col h-screen bg-gray-100 relative">
      <Navbar />
      <div className="flex flex-1 overflow-hidden relative">
        <button
          className="lg:hidden fixed left-4 z-30 bg-white p-2 rounded-md shadow-md"
          style={{ top: "calc(64px + 1rem)" }}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? <X /> : <Inbox />}
        </button>

        <aside
          className={`
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} lg:translate-x-0
          fixed lg:relative inset-y-0 left-0 z-20
          w-64 lg:w-96 bg-white shadow-md
          transition-transform duration-300 ease-in-out
          overflow-y-auto
          mt-16 lg:mt-0
        `}
        >
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-4">Bandeja de entrada</h3>
            {threads.map((thread, index) => (
              <div
                key={index}
                className={`p-4 mb-3 rounded-lg cursor-pointer transition-all duration-200 ease-in-out
                  ${
                    selectedThread === thread
                      ? "bg-blue-100 border-2 border-blue-300 shadow-md"
                      : "hover:bg-gray-100 border border-transparent"
                  }
                `}
                onClick={() => handleSelectThread(thread)}
              >
                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                  <div className="flex items-center w-full sm:w-auto">
                    <Mail className="h-6 w-6 flex-shrink-0 text-blue-500 mr-4" />
                    <div className="flex-1 min-w-0 w-full">
                      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-1">
                        <p className="text-sm font-semibold text-gray-900 truncate mr-2">
                          {thread[0].sender}
                        </p>
                        <p className="text-xs text-gray-500">
                          {new Date(thread[0].date).toLocaleDateString()}
                        </p>
                      </div>
                      <p className="text-sm font-medium text-gray-700 mb-2 truncate">
                        {thread[0].subject}
                      </p>
                      <p className="text-xs text-gray-500 truncate">
                        {(() => {
                          let bodyText = thread[0].body.startsWith('<!DOCTYPE html>') || /<html|<body|<div/i.test(thread[0].body)
                            ? thread[0].body.replace(/<[^>]*>/g, '').trim()
                            : thread[0].body.trim();
                          const words = bodyText.split(/\s+/);
                          let result = '';
                          for (let word of words) {
                            if ((result + word).length > 50) {
                              return result.trim() + '...';
                            }
                            result += word + ' ';
                          }
                          return result.trim();
                        })()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </aside>

        <main className="flex-1 overflow-y-auto bg-white lg:ml-4 p-4">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {isBackgroundLoading && (
                <div className="fixed top-0 left-0 right-0 z-50">
                  <div className="bg-blue-500 h-1 w-full">
                    <div className="bg-blue-300 h-1 animate-pulse" style={{width: '100%'}}></div>
                  </div>
                </div>
              )}
              {threads.length === 0 ? (
                <div className="text-center text-gray-500 mt-20">
                  No hay correos en la bandeja de entrada
                </div>
              ) : (
                <>
                  {selectedThread || isComposingNew ? (
                    <div className="max-w-3xl mx-auto">
                      {!isComposingNew && (
                        <>
                          <h2 className="text-2xl font-semibold mb-4">
                            {selectedThread[0].subject}
                          </h2>
                          {selectedThread.map((email) => (
                            <div
                              key={email.message_id}
                              className={`mb-6 ${email.is_sent ? "ml-8" : "mr-8"}`}
                            >
                              <div className="mb-2 flex items-center">
                                {email.is_sent ? (
                                  <ArrowRight className="h-5 w-5 text-blue-500 mr-2" />
                                ) : (
                                  <ArrowLeft className="h-5 w-5 text-gray-500 mr-2" />
                                )}
                                <User className="h-8 w-8 text-gray-400 bg-gray-100 rounded-full p-1 mr-2" />
                                <div>
                                  <p className="font-medium">{email.sender}</p>
                                  <p className="text-sm text-gray-500">
                                    {new Date(email.date).toLocaleString()}
                                  </p>
                                </div>
                              </div>
                              <div
                                className={`p-4 rounded-lg ${
                                  email.is_sent ? "bg-blue-50" : "bg-gray-50"
                                }`}
                              >
                                <p className="mb-2"><strong>Para:</strong> {email.recipient}</p>
                                {email.cc && email.cc.length > 0 && (
                                  <p className="mb-2"><strong>CC:</strong> {email.cc.join(", ")}</p>
                                )}
                                {email.bcc && email.bcc.length > 0 && (
                                  <p className="mb-2"><strong>BCC:</strong> {email.bcc.join(", ")}</p>
                                )}
                                <EmailBody body={email.body} />
                                <style>{emailStyles}</style>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {!isComposingNew && selectedThread && (
                        <div className="flex space-x-2 mb-4">
                          <button
                            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg flex items-center hover:bg-gray-300 transition duration-150"
                            onClick={() => handleReply("reply")}
                          >
                            <Reply className="mr-2" size={16} />
                            Responder
                          </button>
                          <button
                            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg flex items-center hover:bg-gray-300 transition duration-150"
                            onClick={() => handleReply("replyAll")}
                          >
                            <ReplyAll className="mr-2" size={16} />
                            Responder a todos
                          </button>
                          <button
                            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg flex items-center hover:bg-gray-300 transition duration-150"
                            onClick={() => handleReply("forward")}
                          >
                            <Forward className="mr-2" size={16} />
                            Reenviar
                          </button>
                        </div>
                      )}
                      {replyRecipients && (
                        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mt-6">
                          <div className="mb-4">
                            {isComposingNew && (
                              <>
                                <div className="flex items-center mb-2">
                                  <span className="mr-2">Para:</span>
                                  <input
                                    type="text"
                                    className="w-full p-2 border rounded"
                                    value={replyRecipients.to.join(", ")}
                                    onChange={(e) => handleRecipientChange('to', e.target.value)}
                                  />
                                </div>
                                <div className="flex items-center mb-2">
                                  <span className="mr-2">Asunto:</span>
                                  <input
                                    type="text"
                                    className="w-full p-2 border rounded"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    placeholder="Escribe el asunto del correo"
                                  />
                                </div>
                              </>
                            )}
                            {!isComposingNew && (
                              <div className="flex items-center mb-2">
                                <span className="mr-2">Para:</span>
                                <input
                                  type="text"
                                  className="w-full p-2 border rounded"
                                  value={replyRecipients.to.join(", ")}
                                  onChange={(e) => handleRecipientChange('to', e.target.value)}
                                />
                              </div>
                            )}
                            <div className="flex items-center mb-2">
                              <span className="mr-2">CC:</span>
                              <input
                                type="text"
                                className="w-full p-2 border rounded"
                                value={replyRecipients.cc.join(", ")}
                                onChange={(e) => handleRecipientChange('cc', e.target.value)}
                              />
                            </div>
                            <div className="flex items-center mb-2">
                              <span className="mr-2">BCC:</span>
                              <input
                                type="text"
                                className="w-full p-2 border rounded"
                                value={replyRecipients.bcc.join(", ")}
                                onChange={(e) => handleRecipientChange('bcc', e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="flex items-center mb-4">
                            <textarea
                              className="flex-grow p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                              rows="3"
                              value={suggestion}
                              onChange={handleSuggestionChange}
                              placeholder="Escribe una sugerencia para la respuesta..."
                              disabled={isWaitingForApproval || isGenerating || isRecording}
                            />
                            <button
                              className={`ml-2 p-2 rounded-full ${
                                isRecording ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-600'
                              } hover:bg-opacity-80 transition duration-150`}
                              onClick={isRecording ? stopRecording : startRecording}
                              disabled={isWaitingForApproval || isGenerating}
                            >
                              {isRecording ? (
                                <StopCircle className="h-6 w-6" />
                              ) : (
                                <Mic className="h-6 w-6" />
                              )}
                            </button>
                          </div>
                          {!isWaitingForApproval && (
                            <div className="flex justify-end">
                              <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition duration-150 disabled:opacity-50"
                                onClick={handleGenerateResponse}
                                disabled={!suggestion || isGenerating}
                              >
                                {isGenerating ? (
                                  <RefreshCw className="mr-2 animate-spin" size={16} />
                                ) : (
                                  <Send className="mr-2" size={16} />
                                )}
                                {isGenerating ? "Generando..." : "Generar Respuesta"}
                              </button>
                            </div>
                          )}
                          {isWaitingForApproval && (
                            <div className="mt-4">
                              <h3 className="font-semibold mb-2">
                                Respuesta Generada (Editable):
                              </h3>
                              <textarea
                                className="w-full p-3 mb-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                                rows="6"
                                value={editableResponse}
                                onChange={handleEditResponse}
                              />
                              <div className="flex justify-end space-x-2">
                                <button
                                  className="bg-green-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-600 transition duration-150 disabled:opacity-50"
                                  onClick={handleApproveResponse}
                                  disabled={isGenerating || isSending}
                                >
                                  {isSending ? (
                                    <RefreshCw className="mr-2 animate-spin" size={16} />
                                  ) : (
                                    <ThumbsUp className="mr-2" size={16} />
                                  )}
                                  {isSending ? "Enviando..." : "Aprobar y Enviar"}
                                </button>
                                <button
                                  className="bg-yellow-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-yellow-600 transition duration-150 disabled:opacity-50"
                                  onClick={handleRegenerateResponse}
                                  disabled={isGenerating || isSending}
                                >
                                  {isGenerating ? (
                                    <RefreshCw className="mr-2 animate-spin" size={16} />
                                  ) : (
                                    <RefreshCw className="mr-2" size={16} />
                                  )}
                                  {isGenerating ? "Regenerando..." : "Regenerar"}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-center text-gray-500 mt-20">
                      Selecciona un hilo para ver los mensajes o crea un nuevo correo
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </main>
      </div>

      {/* Botón flotante para Nuevo Correo */}
      <button
        className="fixed bottom-6 right-6 bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center z-50"
        onClick={() => handleReply("new")}
        aria-label="Nuevo Correo"
      >
        <PenTool size={24} />
        <span className="ml-2 hidden sm:inline">Nuevo Correo</span>
      </button>

      {notification && (
        <Alert
          className="fixed bottom-4 right-4 max-w-sm"
          variant={notification.type === "error" ? "destructive" : "default"}
        >
          <AlertTitle>
            {notification.type === "error" ? "Error" : "Éxito"}
          </AlertTitle>
          <AlertDescription>{notification.message}</AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default EmailResponseApp;