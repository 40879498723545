import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { configureEmailProvider, getUserEmailConfig, getUserProfile } from '../services/api';

import Navbar from './Navbar';

const EmailConfig = () => {
  const [provider, setProvider] = useState('');
  const [config, setConfig] = useState({});
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [currentConfig, setCurrentConfig] = useState(null);

  useEffect(() => {
    fetchCurrentConfig();
  }, []);

  const fetchCurrentConfig = async () => {
    try {
      const response = await getUserEmailConfig();
      setCurrentConfig(response.data);
      if (response.data) {
        setProvider(response.data.email_provider);
        setConfig(response.data);
      }
    } catch (err) {
      //setError('No se pudo obtener la configuración actual');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (provider === 'gmail') {
      oauthSignIn();
    } else {
      try {
        // Crear una copia del objeto config
        const configToSend = { ...config };
        
        // Si la contraseña es solo asteriscos, eliminarla del objeto
        if (configToSend.password && /^\*+$/.test(configToSend.password)) {
          delete configToSend.password;
        }
        
        await configureEmailProvider(provider, configToSend);
        const userProfile = await getUserProfile();
        if (!userProfile.data.prompt || userProfile.data.prompt.trim() === '') {
          navigate('/prompt-config');
        } else {
          navigate('/dashboard');
        }
      } catch (err) {
        setError(err.response?.data?.message || 'Ocurrió un error durante la configuración');
      }
    }
  };

  const oauthSignIn = () => {
    const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
    
    const form = document.createElement('form');
    form.setAttribute('method', 'GET');
    form.setAttribute('action', oauth2Endpoint);

    const params = {
      client_id: "1029494310519-ucgdggjo3flscr8a0e8ulpimce8vivk7.apps.googleusercontent.com",
      redirect_uri: "https://email.emalia.io/auth",
      response_type: "code",
      scope: "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send",
      include_granted_scopes: "true",
      state: JSON.stringify({
        email_provider: "gmail",
        user_id: localStorage.getItem('user_id'),
      }),
      access_type: "offline",
      prompt: "consent",
    };

    for (const p in params) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  };

  const renderConfigFields = () => {
    switch (provider) {
      case 'gmail':
        return (
          <p className="text-center">Haz clic en el botón "Iniciar sesión con Google" para autorizar el acceso a Gmail</p>
        );
      case 'outlook':
        return (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Correo electrónico
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="email"
                type="email"
                placeholder="Correo electrónico"
                value={config.email || ''}
                onChange={(e) => setConfig({ ...config, email: e.target.value })}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Contraseña
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="password"
                type="password"
                placeholder="Contraseña"
                value={config.password || ''}
                onChange={(e) => {
                  // Si el valor actual es asteriscos y el nuevo valor también lo es, no actualizar
                  if (!/^\*+$/.test(config.password) || !/^\*+$/.test(e.target.value)) {
                    setConfig({ ...config, password: e.target.value });
                  }
                }}
                required
              />
            </div>
          </>
        );
      case 'custom':
        return (
          <>
            <h3 className="text-lg font-semibold mb-2">Configuración SMTP (para enviar correos)</h3>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="smtp_server">
                Servidor SMTP
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="smtp_server"
                type="text"
                placeholder="Servidor SMTP (Ej: smtp.serveraddress.com)"
                value={config.smtp_server || ''}
                onChange={(e) => setConfig({ ...config, smtp_server: e.target.value })}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="smtp_port">
                Puerto SMTP
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="smtp_port"
                type="number"
                placeholder="Puerto SMTP"
                value={config.smtp_port || ''}
                onChange={(e) => setConfig({ ...config, smtp_port: e.target.value })}
                required
              />
            </div>
            <h3 className="text-lg font-semibold mb-2 mt-4">Configuración IMAP (para recibir correos)</h3>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="imap_server">
                Servidor IMAP
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="imap_server"
                type="text"
                placeholder="Servidor IMAP (Ej: imap.serveraddress.com)"
                value={config.imap_server || ''}
                onChange={(e) => setConfig({ ...config, imap_server: e.target.value })}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="imap_port">
                Puerto IMAP
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="imap_port"
                type="number"
                placeholder="Puerto IMAP"
                value={config.imap_port || ''}
                onChange={(e) => setConfig({ ...config, imap_port: e.target.value })}
                required
              />
            </div>
            <h3 className="text-lg font-semibold mb-2 mt-4">Información de la cuenta</h3>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Correo electrónico
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="email"
                type="email"
                placeholder="Correo electrónico"
                value={config.email || ''}
                onChange={(e) => setConfig({ ...config, email: e.target.value })}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Contraseña
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="password"
                type="password"
                placeholder="Contraseña"
                value={config.password || ''}
                onChange={(e) => {
                  // Si el valor actual es asteriscos y el nuevo valor también lo es, no actualizar
                  if (!/^\*+$/.test(config.password) || !/^\*+$/.test(e.target.value)) {
                    setConfig({ ...config, password: e.target.value });
                  }
                }}
                required
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      <div className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="max-w-4xl mx-auto">
          <form onSubmit={handleSubmit} className="bg-white shadow-xl rounded-lg px-8 pt-6 pb-8 mb-4">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Conecta tu cuenta de correo electrónico</h2>
            <p className='mb-4 text-center'>Para configurar tu panel de Emalia para enviar y recibir correos electrónicos, necesitas conectar tu cuenta de correo.</p>
            {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="provider">
                Elige tu proveedor de correo electrónico
              </label>
              <select
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="provider"
                value={provider}
                onChange={(e) => setProvider(e.target.value)}
                required
              >
                <option value="">Selecciona un proveedor</option>
                <option value="gmail">Gmail</option>
                <option value="outlook">Outlook</option>
                <option value="custom">SMTP/IMAP personalizado</option>
              </select>
            </div>
            {renderConfigFields()}
            <div className="flex items-center justify-center mt-6">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                type="submit"
              >
                {provider === 'gmail' ? 'Iniciar sesión con Google' : 'Guardar configuración'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailConfig;