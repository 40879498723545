import React, { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import EmailConfig from './components/EmailConfig';
import EmailResponseApp from './components/EmailResponseApp';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Profile from './components/Profile';
import PromptConfig from './components/PromptConfig';
import Register from './components/Register';

const CURRENT_VERSION = process.env.REACT_APP_CURRENT_VERSION;

const App = () => {
  useEffect(() => {
    const storedVersion = localStorage.getItem("appVersion");
    if (storedVersion !== CURRENT_VERSION && storedVersion !== null) {
      console.log("Version change detected. Clearing localStorage and refreshing.");
      localStorage.clear();
      localStorage.setItem("appVersion", CURRENT_VERSION);
      //window.location.reload();
    } else if (storedVersion === null) {
      localStorage.setItem("appVersion", CURRENT_VERSION);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/email-config" element={<EmailConfig />} />
            <Route path="/prompt-config" element={<PromptConfig />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<EmailResponseApp />} />
          </Route>
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;